#carausel-8-columns > .card-1{
    width: 183px;
}
.sort-price-dropdown{
    width: max-content;
}
@media only screen and (max-width: 414px)  {
    .register-page,
    .login-page,
    .my-account-details{
        padding: 40px 0 !important;
    }
    .newsletter .newsletter-inner{
        overflow: auto !important;
        margin-bottom: 100px  !important
    }
    .newsletter-content .form-subcriber{
        position: absolute !important;
        margin-top: 100px  !important;
    }
    .hero-slider-1 .single-hero-slider{
        border-radius: 10px !important;
    }
    .single-hero-slider.single-animation-wrap{
        height: 65% !important;
    }
    .hero-slider-1 .slider-content {
        top: 25% !important;
    }
    .home-slide-cover{
        margin-bottom: -50% !important;
    }
    #carausel-8-columns > .card-1{
        margin:10px 5px !important;
        width: 177px !important;
    }
    .carausel-8-columns{
        justify-content: space-evenly;
    }
    .product-grid-4{
        justify-content: space-evenly;
    }
    .inner-product-grid {
        width: 187px !important;
        margin: 0 0px !important;
    }
    .product-img-action-wrap{
        padding: 0px !important;
    }
    .product-content-wrap{
        padding: 8px !important;
    }
    .product-cart-wrap .product-card-bottom{
        display: block;
        margin: 0px;
    }
    .product-cart-wrap .product-card-bottom div a{
        text-align: center;
        width: 90% !important;
    }
    .product-card-bottom .add-cart{
        margin: 5px 0 !important;
        display: flex;
        justify-content: center;
    }
    .dashboard-content{
        margin-top: 10px !important;
        padding-left: 0% !important;
    }
    .dashboard-order{
        display: block !important;
    }
    .dashboard-order-list{
        display: flex !important;
    }
    .cart-totals{
        margin-top:20px !important;
    }
    .delete-cart{
        padding: 10px 0px !important;
    }
    .detail-info,
    .whishlist-padding{
        padding: 10px 0px !important;
    }
    .mobile-whishlist,
    .mobile-cart{
        display: none !important;
    }
    .product-category-name{ 
        width: auto !important; 
    }
}
@media only screen and (max-width:360px) {
    .inner-product-grid {
        width: 100% !important;
    }
    #carausel-8-columns > .card-1{
        width: 80% !important;
    }
}
@media only screen and (width:540px) {
    .inner-product-grid {
        width: 270px !important;
    }
    #carausel-8-columns > .card-1{
        width: 240px !important;
    }
    .mobile-whishlist,
    .mobile-cart{
        display: block !important;
    }
    .mobile-w-c{
        display: none !important;
    }
    .table-wishlist thead{
        display: none !important;
    }
    .mobile-single-product{
        margin-top: 15px !important;
    }
}
@media only screen and (max-width:1000px) {
    .logo.logo-width-1 a img,
    .mobile-header-logo{
        width: 80px !important;
        min-width: 80px !important;
    }
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .tab-content.account.dashboard-content{
      margin-top: 10px  !important;
    }
}
.header-style-1 .search-style-2 form input{
    width: 100% !important;
}
.banner-img.style-3{
    height: 100%  !important;
}