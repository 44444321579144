#carausel-8-columns > .card-1{
    display: inline-block;
}

#carausel-4-columns > .slick-list > .product-cart-wrap{
    width: 278px;
    display: inline-block;
    /* card-1 slick-slide slick-cloned slick-active */
}

.more_categories:hover{
    cursor: pointer;
}

.more-cat-box{
    height: 500px;
    overflow-x:hidden ;
    overflow-y:scroll;
}

#navbar-bottom-categories{
    width: 75%;
}

.link-disabled{
    opacity: 0.7;
    pointer-events: none;
}

.see-all-brands{
    max-height: 297px;
    overflow: auto;
}

.form-control-xs{
    height:50px;
}

.wisilist-fill{
    font-size: 20px !important;
}

.categories-button-active{
    width: max-content;
}

.main-menu.main-menu-padding-1 > nav{
    width: max-content;
}

.sub-menu{
    position: absolute;
    width: fit-content;
    left: 93%;
    padding-left: 15px !important;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 0;
    padding-top: 1.25rem;
    list-style: none;
    background-color: #fff;
    border: 1px solid var(--color-border);
    border-top: 0;
    border-bottom-right-radius: var(--size-border-radius);
    white-space: nowrap;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.25s cubic-bezier(0.28, 0.12, 0.22, 1);
}

.parent-cat:hover + .sub-menu {
    opacity: 1;
    visibility: visible;
    height: fit-content;
    border: 1px solid #BCE3C9;
    border-radius: 10px;
}

.sub-menu:hover{
    opacity: 1;
    visibility: visible;
    height: fit-content;
    border: 1px solid #BCE3C9;
    border-radius: 10px;
}

.sub-menu + .sub-cat{
    width: max-content;
}

.show_cat{
    opacity: 1;
    visibility: visible;
    height: fit-content;
    border: 1px solid #BCE3C9;
    border-radius: 10px;
}

.sub-menu li{
    /* display: flex; */
    /* min-width: max-content;
    max-width: max-content;
    width: max-content; */
    align-items: center ;
    line-height: 48px;
    border-radius: 5px;
    border: 1px solid #F2F3F4;
    padding: 9px 18px ;
    margin: 0 15px 15px 0;
    height: 50px;
    transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
}

.sub-cat:hover {
    border: 2px solid #BCE3C9 !important;
    box-shadow: 5px 5px 15px rgb(0 0 0 / 5%) !important;
    transition: .3s;
    cursor: pointer;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    -o-transition: .3s;
}
.hotline p{
    font-size: 22px !important;
}
.slider-content{
    display: none !important;
}
.slick-cloned:hover{
    border: 2px solid  var(--primary-color);
    opacity: 0.8;
}
.product-cart-wrap .product-img-action-wrap{
    padding: 0px !important;
}
.product-cart-wrap .product-img-action-wrap .product-img{
    border-radius: 0px !important;
}