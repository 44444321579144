/* theme color change */
:root{
    --white-color:#fff;
    --dark-background:#121212;
    --dark-container:#1f1f1f;
}
.home-slider.style-2{
    background-color: var(--dark-background) !important;
}
/* theme color dark */

body{
background-color: var(--dark-background) !important;
}
section{
background-color: var(--dark-background) !important;
}
.banner-left-icon , .banner-left-icon .icon-box-title,.banner-left-icon p {
background-color: var(--dark-container) !important;
color:var(--white-color) !important;
}
.banner-text h3,.banner-text p,
.detail-extralink .detail-qty a:hover{
color: var(--white-color) !important;
}
.card-1,.product-cart-wrap{
background: var(--dark-container) !important;
border: var(--dark-container) !important;
}
.home-slider .style-2{
background-color: var(--dark-background) !important;
}   
.header-style-1 .header-bottom-bg-color {
background: var(--dark-background) !important;
border: var(--dark-background) !important;
}
.header-style-1 .search-style-2 form ,
.header-style-1 .search-style-2 form input{
color: var(--white-color) !important;
background-color: var(--dark-background) !important;
border-color: var(--primary-color) !important;
}
.banner-left-icon .banner-text h3{
color: var(--white-color) !important;
}
.main-menu > nav > ul > li > a {
    color: var(--primary-color) !important;
}
.main-menu > nav > ul > li > a.active {
    color: var(--white-color) !important;
}
.main-menu > nav > ul > li > a:hover{
color: var(--white-color) !important;
}
.categories-button-active,
.product-cart-wrap:hover,
.shopping-summery button,
.btn{
border: 1px solid var(--primary-color) !important;
}
.cart-dropdown-wrap.account-dropdown,
.form-control-xs {
background-color: var(--dark-container) !important;
border-color: var(--primary-color) !important;
color: var(--white-color) !important;
}
.hero-slider-1 .slider-content p ,
.hero-slider-1 .slider-content h1,
.banner-img.style-3 .banner-text h2,
.banner-img .banner-text h4{
color: var(--dark-container) !important;
}
.product-cart-wrap .product-badges span.hot,
.divider-2 {
background-color: var(--primary-color) !important;
}
.card-1,
.product-cart-wrap{
border:1px solid var(--dark-background) !important;
}
.card-1:hover{
border-color: var(--primary-color) !important;
background-color: transparent;
}
.footer-bottom,
.sidebar-widget ul li{
border-color: var(--primary-color) !important;
}
.page-header.breadcrumb-wrap,
.page-header.breadcrumb-wrap.conatiner{
background-color: var(--dark-container) !important;
border-bottom-color: var(--primary-color) !important;
}
.table-wishlist tr,
.table-wishlist thead tr th,
.primary-sidebar .sidebar-widget{
border-color:  var(--dark-container) !important;
background-color: var(--dark-container) !important;
border-radius: 0 !important;
}
.detail-extralink .detail-qty,
.cart-totals,
.card,
.team-card .content {
background-color: var(--dark-container) !important;
}
.main-heading{
border-radius: 0px !important;
}
.sort-by-product-wrap,
.sort-by-dropdown,
.dashboard-menu ul li,
.featured-card{
background-color: var(--dark-container) !important;
color: var(--white-color) !important;
border-color: var(--dark-container) !important;
}
.form-check-label span{
color: var(--white-color) !important;
}
.form-check-input:checked {
border-color: var(--primary-color) !important;
background-color: var(--primary-color) !important;
}
.form-check-input{
border-color: var(--white-color) !important; 
background-color: transparent;
}
.fi-rs-trash{
padding: .6rem !important;
background-color: var(--primary-color) !important;
border-radius: 50% !important;
color: var(--dark-container) !important;
}
.bg-white,
.heading_s1,
.categories-dropdown-wrap,
.sub-menu{
background-color: var(--dark-background) !important;
color: var(--white-color) !important;
border-color: var(--dark-background) !important;
}
.form-group input,
.form-group select,
.form-group select option,
.form-group textarea,
.parent-cat,
.sub-cat,
.woocommerce-input-wrapper input,
.woocommerce-input-wrapper select,
.woocommerce-input-wrapper select option,
.woocommerce-input-wrapper textarea,
.contact-form-style div div .input-style input,
.contact-form-style div div .textarea-style textarea{
background-color: var(--dark-container) !important;
border-color: var(--dark-container) !important;
color: var(--white-color) !important;
}
.table-responsive table thead tr,
.table-responsive table tbody tr{
border-color: var(--primary-color) !important;
background-color: var(--dark-container) !important;
color: var(--white-color) !important;
}
.login_wrap div div p a:hover,
.login_footer a:hover,
.featured-card a:hover,
.payment_option .custome-radio .form-check-label  {
color: var(--white-color) !important;
}
.card-body form div.form-group input{
border-color: var(--primary-color) !important;
}
.login-image{
border-radius: 50% !important;
width: 80% !important
}
.form-group select{
height: 65px;
border-radius: 10px !important;
}
.detail-qty.border.radius input{
background-color: var(--dark-container) !important;
color: var(--white-color) !important;
}
.button-add-to-cart:hover{
color: var(--primary-color) !important;
}
.product-extra-link2 .action-btn.hover-up{
background-color: var(--dark-container) !important;
border-color: var(--primary-color) !important;
color: var(--primary-color) !important;
}
.section-title.style-1::after,
.widget-category-2 .count{
    background-color: var(--primary-color) !important;
    color: var(--dark-container);
}
.stock-status.out-stock {
    background-color: var(--primary-color) !important;
    color: var(--dark-container) !important;
}
.view-text:hover,
.breadcrumb a:hover{
    color: var(--cart-button-bg-color);
}
.contact-form-style div div button{
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}
#contact-form div div button:hover{
    background-color:var(--dark-background) !important;
    color: var(--white-color) !important;
}
.mobile-menu{
    color: var(--primary-color);
}
.show-dropdown{
    display:none !important;
}
.icon-mobile-menu i{
    color: var(--primary-color);
}
.mobile-menu-search{
    background-color: var(--dark-background) !important;
    border: 1px solid var(--primary-color) !important;
}
.mobile-search button{
    border-radius: 10px 0px 0px 10px !important;
}
.mobile-search button{
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}
.page-arrow{
    background-color: var(--dark-container) !important;

}
.page-arrow:hover,
.page-number:hover{
    border: 1px solid var(--primary-color) !important;
}
.page-number:hover{
    background-color: var(--dark-container) !important;
}